:root {
  --red: #972d47;
}

.header {
  text-align: right;
}

em {
  font-style: normal;
}

.rounded {
  object-fit: cover;
  border-radius: 8px;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
}

.frame {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 95%;
}

#landing-top {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

#landing-user {
  display: flex;
  justify-content: flex-end;
  margin: 1em;
}

#landing-col {
  display: flex;
  width: 50%;
  min-width: 650px;
  flex-direction: column;
  justify-content: center;
}

#icons-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5%;
}

#search-header {
  background-color: white;
  height: auto;
  border-bottom: 1px solid #d6d6d6;
  padding-top: 2em;
  padding-bottom: 2em;
}

.header {
  background-color: white;
  height: auto;
  min-width: 916px;
  border-bottom: 1px solid #d6d6d6;
  padding-top: 2em;
  padding-bottom: 2em;
}

.content {
  margin: 0 3%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.pageBody {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.image-collage {
  display: flex;
  flex-wrap: wrap;
}

#search-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  vertical-align: middle;

}

#search-backlink {
  margin-top: 2em;
}

#search-paging {
  display: flex;
  justify-content: space-between;
}

#box {
  background: var(--red);
  width: 8px;
  height: 40px;
}

#navbar {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  gap: 2em;
  justify-content: space-between;
  align-items: center;
}

.lf-logo {
  display: flex;
  cursor: pointer;
}

.icons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon-link p {
  color: black;
  font-size: 14px;
  margin: 0;
}

.user {
  display: flex;
  gap: 5px;
  color: black;
  font-size: 14px;
}

.categories {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.category {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: nowrap;
  padding: 0.5em;
}

.category-title {
  margin: 0 !important;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.active {
  background-color: var(--red);
  border-radius: 8px;
}

.active .category-title {
  color: white !important;
}

.search-body {
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
}

.search-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-buttons {
  display: flex;
  gap: 1%;
  justify-content: flex-end;
  margin-left: 5em;
}

.card-header {
  margin-top: 1em;
}

.categories {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.results {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 1em;
}

.result-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.result-text-row {
  display: flex;
  gap: 1em;
}

.result-text-values {
  display: flex;
  flex-direction: row;
}

.result-text-body {
  white-space: nowrap;
  cursor: default;
}
.result-text-title {
  white-space: nowrap;
  cursor: default;
}

.tooltip {
  text-overflow: ellipsis;
  overflow: hidden;
}

.result-info {
  cursor: default;
}

.data-row {
  width: 100%;
}
.popup-buttons {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1em;
}

.divider {
  margin-top: 1em;
  margin-bottom: 1em;
}

.info {
  display: flex;
  flex-direction: column;
}

.info-title {
  margin-bottom: 1em !important;
}

.show-more-row {
  margin-top: 1em;
}

.show-more-text {
  color: var(--red);
  cursor: pointer;
}
.red {
  color: var(--red);
}

.filters {
  width:max-content
}

.product-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 1em;
}

.product-tags {
  display: flex;
  gap:5px;
}

@media (min-width: 1250px) {
  .product-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1650px) {
  .product-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

.card {
  width: 100%;
}

.product-card {
  max-width: fit-content;
}

.facility-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.export-button {
  background: #303f49;
  color: white;
  display: flex;
  gap: 0.5em;
}

.address-row {
  margin-bottom: 1em;
}

.product-searchbar {
  display: flex;
  justify-content: space-between;
  background-color: #d6d6d6;
  border-radius: 8px;
  width: 100%;
  padding: 1em;
  margin: 1em 0 1em 0;
}

.product-searchbar-left {
  display: flex;
  align-items: center;
  gap: 3em;
  margin-left: 1em;
}
.product-searchbar-right {
  display: flex;
  align-items: center;
  gap: 1em;
}

.product-filter-switch {
  display: flex;
  gap: 1em;
}

.product-img {
  padding: 1em 2em 1em 2em;
}

#map {
  width: 100%;
}

.leaflet-container {
  width: 100%;
  height: 50vh;
  background-color: rgba(255, 0, 0, 0) !important;
}

/* Search Box style override */
.search-box {
  width: 100%;
}

.sui-search-box__text-input {
  border-radius: 30px;
  box-shadow: none !important;
}

.sui-search-box__text-input:not(:placeholder-shown):focus {
  border-radius: 30px 30px 0 0;
}

.sui-search-box__autocomplete-container {
  border-radius: 0 0 30px 30px;
  top: 100%;
  padding: 1em;
  box-shadow: none;
  border-top: none;
}
.autocomplete-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.autocomplete-row {
  display: flex;
  gap: 0.5em;
  background-color: none;
  border-radius: 0.5em;
  padding: 10px;
}

.autocomplete-row .clickable {
  width: 100%;
}

.autocomplete-span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.span-left {
  display: flex;
  gap: 10px;
  align-items: center;
}

.autocomplete-row:hover {
  background-color: rgb(50, 73, 219);
  color: white;
}
